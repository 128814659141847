import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';
import MainMenu from '../components/MainMenu/Main.vue';
import Menu from '../components/MainMenu.vue';
Vue.use(VueRouter);

const routes = [
  // {
  //   path: '/contacts',
  //   name: 'Contacts',
  //   meta: { title: 'お問い合わせ' },
  //   components: { mainscreen: Contacts },
  // },
  {
    path: '/',
    name: 'Login',
    meta: { title: 'ログイン', hideForAuth: true },
    // components: { mainscreen: Contacts },
    component: Login,
  },
  {
    path: '/menu',
    name: 'Menu',
    meta: { title: 'Menu', requiresAuth: true },
    // components: { mainscreen: Contacts },
    component: MainMenu,
  },
  {
    path: '/main',
    name: 'Main',
    meta: { title: 'Menu', requiresAuth: true },
    // components: { mainscreen: Contacts },
    component: Menu,
  },
];

export const router = new VueRouter({
  // base: '/',
  // mode: 'history',
  routes,
  // eslint-disable-next-line
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const auth = {
    loggedIn: false,
    islogin() {
      if (Vue.ls.get('api_token') !== null) {
        this.loggedIn = true;
      }
    },
  };
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // このルートはログインされているかどうか認証が必要です。
    // もしされていないならば、ログインページにリダイレクトします。
    auth.islogin();
    if (auth.loggedIn === false) {
      console.log('attempt to access without authorization');
      next({
        path: '/',
      });
    } else {
      next();
    }
  } else {
    // next() を常に呼び出すようにしてください
    next();
  }
  // move to main menu if user authentification is still valid
  if (to.matched.some(record => record.meta.hideForAuth)) {
    if (Vue.ls.get('api_token') !== null) {
        next({ path: '/menu' });
    } else {
      next();
    }
  } else {
    next();
  }
});
export default router;
