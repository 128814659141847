<template>
  <div>
    <DentalArchesSVG :id="mapID" class="figure" :key="reRenderCounter"/>
  </div>
</template>

<script>
import DentalArchesSVG from '../assets/dental_arches.svg';
import { EventBus } from '../EvenBus/event-bus.js';

export default {
  name: 'TeethMapView',
  components: {
    DentalArchesSVG,
  },
  props: {
    treateadTeeth: {
      type: Array,
      default: () => [],
    },
    mapIndex: {
      type: Number,
      default: () => 0,
    },
  },
  beforeMount () {
    this.mapID = `map-${this.mapIndex}`;
  },
  mounted() {
    EventBus.$on('show-map', (index) => {
      if(index === this.mapIndex) {
        this.colorTeeth(index);
      }
    });
    this.$nextTick(() => {
      this.colorTeeth(this.mapIndex);
    });
  },
  data() {
    return {
      mapID: '',
      reRenderCounter: 0,
    }
  },
  methods: {
    colorTeeth(map_index) {
      this.treateadTeeth.forEach(tooth => {
        if( tooth.number >= 0 ){ // tooth.number=-1のケースがありjsエラーとなっていたため、fail safeを仕掛けます。
          document.querySelector(`#map-${map_index} > #s${tooth.number} .st0`).style.fill = '#B3E5FC';
          document.querySelector(`#map-${map_index} > #s${tooth.number} .st1`).style.fill = '#B3E5FC';
        }
      });
    },
    updateMap() {
      this.reRenderCounter += 1;
    },
    highLight() {
      this.treateadTeeth.forEach(tooth => {
        if( tooth.number >= 0 ){
          document.querySelector(`#${this.mapID} > #s${tooth.number} .st0`).style.fill = '#B3E5FC';
          document.querySelector(`#${this.mapID} > #s${tooth.number} .st1`).style.fill = '#B3E5FC';
        }
      });
    },
  },
};
</script>

<style lang="scss">
.figure .st0 {
  fill: transparent;
  fill-opacity: 0.4;
}
// color tooth
.figure .st1 {
  stroke: #444;
  stroke-width: 2px;
  fill: white;
}
// slightly accent tooth
.figure .st2 {
  stroke: #444;
  fill: transparent;
}
.treatedTooth {
  fill: #B3E5FC;
}
</style>
