<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col>
        <v-row align="center" justify="center">
          <v-btn
            rounded
            x-large
            width="55%"
            class="my-4"
            color="blue lighten-1 white--text"
            @click="onClickScanData"
          >
            <v-icon left>mdi-bluetooth-transfer</v-icon>
            {{ $t('action.scan_data') }}
          </v-btn>
        </v-row>

        <notifications group="error" position="top center" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'MainPage',
  mounted() {},
  props: {
    permissionObj: Object,
  },
  data: () => ({}),
  methods: {
    onClickScanData() {
      this.$router.push({ path: '/main' });
    },
  },
};
</script>
