<template>
  <v-form ref="form">
    <notifications group="error" position="top center"/>

    <v-container>
      <div v-if="!isProduction" class="primary--text text-sm-h4 text-center">{{ $t('p.guidance_for_test_mode') }}</div>
      <v-row>
        <v-spacer></v-spacer>
        
        <v-col cols="12" sm="8" md="6" xl="4">
          <v-text-field
            v-model="email"
            :rules="[rules.required]"
            name="email"
            label="ユーザー名/メールアドレス"
            autofocus
          ></v-text-field>

          <v-text-field
            v-model="password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="toggleShowPassword"
            :rules="[rules.required]"
            :type="showPassword ? 'text' : 'password'"
            name="password"
            label="パスワード"
            counter>
          </v-text-field>

          <v-btn
            color="success"
            class="pa-5 mt-5"
            @click="login"
          >
            ログイン
          </v-btn>
        </v-col>

        <v-spacer></v-spacer>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Login',
  data: () => ({
    isProduction: process.env.NODE_ENV == 'production',
    email: '',
    showPassword: false,
    password: '',
    rules: {
      required: value => !!value || '必須',
    },
  }),
  methods: {
    toggleShowPassword () {
      this.showPassword = !this.showPassword;
    },
    login () {
      if (this.$refs.form.validate()) {
        axios({
          method: 'post',
          url: `${process.env.VUE_APP_API_BASEURL}/wf/login_on_transmission_app`,
          data: {
            email: this.email,
            password: this.password,
          },
        })
        .then((response) => {
          if( response.data.response.id == -1 ){
            this.$notify({
              group: 'error',
              title: '不適切なユーザーです。適切な役割が設定されているアカウントでログインしてください。',
              type: 'error',
              position: 'left',
              text: '',
            });
          }else{
            const userData = response.data.response;
            console.log(userData);
            const expire = 24 * 60 * 60 * 1000; // login session expires after 24 hours
            this.$ls.set('api_token', userData.token, expire);
            this.$ls.set('user_id', userData.user_id, expire);
            this.$ls.set('user', userData.userdata, expire);
            this.$ls.set('user_display_name', userData.user_display_name, expire);
            this.$ls.set('clinics', userData.clinics, expire);
            this.$ls.set('user_role', userData.role_name, expire);
            this.$router.push({ path: '/menu' });
          }
        })
        .catch((error) => {
          // console.log(error.response);
          if ( typeof error.response == "undefined") {
            this.$notify({
              group: 'error',
              title: error.message,
              type: 'error',
              position: 'left',
              text: '',
            });
          } else if (error.response.status !== undefined && error.response.status === 400) {
            // this.errorResponse = 'ログイン処理に失敗しました。\n通信環境およびID/パスワード\nをご確認ください。';
            this.$notify({
              group: 'error',
              title: 'ログイン処理に失敗しました。',
              type: 'error',
              position: 'left',
              text: '通信環境およびID/パスワードをご確認ください。',
            });
          } else {
            this.$notify({
              group: 'error',
              title: `エラー ${error.response.status}`,
              type: 'error',
              position: 'top center',
              text: error.message + " " + ( error.response.data && error.response.data.message ? error.response.data.message : "" ),
            });
            console.log(error.response);
          }
        });
      }
    },
  },
}
</script>
