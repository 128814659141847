export default {
  en: {
    action: { // Actions
      action: 'Action',
      edit: 'Edit',
      delete: 'Delete',
      save: 'Save',
      cancel: 'Cancel',
      new_item: 'New Item',
      edit_item: 'Edit Item',
      filter_by: 'Filter by {key}',
      logout: 'Logout',

      scan_data: 'Scan data',
      teeth_evaluation: 'Teeth evaluation',
      submit: 'Submit',
      reconnect: 'Reconnect',
      terminate_transmission: 'Terminate transmission',
      discard: 'Discard',
      choose_device: 'Choose device',
      confirm: 'Confirm',
      show_tooth_map: 'Show tooth map',
      get_list_of_devices: 'Get list of devices',
      search_device_name: 'Search {deviceName}',
      submit_data: 'Submit data',
  },
    menu: { // Menu items
      menu: 'Menu',
      clinic_groups: 'Clinic Groups',
      clinics: 'Clinics',
      users: 'Users',
      devices: 'Devices',
      patients: 'Patients',
      treatment_sessions: 'Treatment Sessions',

      main: 'Main',
      teeth_evaluation: 'Teeth evaluation',
      logout: 'Logout',
      goto_admin_site: 'AdminSite',
    },
    e: { // Entities
      menu: 'Menu',
      clinic_group: 'Clinic Group',
      clinic: 'Clinic',
      user: 'User',
      device: 'Device',
      patient: 'Patient',
      treatment_session: 'Treatment Session',
    },
    p: { // Properties
      // Common
      name_of_person: 'Name',
      country: 'Country',
      region: 'Region',
      email: 'Email',
      phone_number: 'Phone number',
      remarks: 'Remarks',
      clinic_group_id: 'Clinic group ID',
      clinic_id: 'Clinic ID',
      date: 'Date',
      start_time: 'Start time',
      end_time: 'End time',
      uuid: 'UUID',

      // Clinic groups
      name_of_clinic_group: 'Clinic group name',
      name_pron_of_clinic_group: 'Clinic group name (Furigana)',

      // Clinics
      name_of_clinic: 'Clinic name',
      name_pron_of_clinic: 'Clinic name (Furigana)',

      // Users
      role: 'Role',

      // Devices
      serialnumber: 'Serialnumber',
      status: 'Status',
      name_of_device: 'Device name',
      guidance_for_test_mode: '* It is test mode. This guidance will be removed on live.',

      // Patients
      username: 'Username',
      password: 'Password',
      password_confirmation: 'Password (confirm)',
      nickname: 'Nickname',
      birthdate: 'Birthdate',
      gender: 'Gender',
      registration_number: 'Registration number',
      accept_notification: 'Accept notification',

      // Treatment sessions
      operator: 'Operator',
      category: 'Category',
      recorded_at: 'Recorded at',
      recorded_at_date: 'Recorded at / Date',
      recorded_at_time: 'Time',
      periorank: 'Periorank',

      total_laser: 'Total laser',
      total_liquid: 'Total liquid',
      image_file: 'Image file',
      image: 'Image',

      // Treatments
      tooth_number: 'Tooth number',
      duration: 'Duration',
      water: 'Water',
      laser: 'Laser',
    },
    v: { // Values
      yes: 'Yes',
      no: 'No',
      male: 'Male',
      female: 'Female',
      unassigned: '(Unassigned)',
      empty: '(Empty)',

      // User roles
      role_SystemAdmin: 'System Admin',
      role_ClinicGroupAdmin: 'Clinic Group Admin',
      role_ClinicAdmin: 'Clinic Admin',
      role_Doctor: 'Doctor',
      role_Hygienist: 'Hygienist',
      role_PurchaseStaff: 'Purchase Staff',
      role_OrderStaff: 'Order Staff',
      role_CallCenter: 'Call Center',
      role_SharedAccount: 'Shared Account',

      // Device statuses
      status_InStock: 'In Stock',
      status_InService: 'In Service',

      // Treatment session categories
      category_PeriodonticCare: 'Periodontic Care',
      category_Maintenance: 'Maintenance',

      // BLE
      ble_parameter_name: 'パラメータ名',
      ble_value: '値',

      // Connection statuses
      status_waiting_pairing: 'Waiting for pairing',
      status_pairing: 'Pairing',
      status_paired: 'Paired',
      status_restoring_connection: 'Restoring connection',
      status_disconnected: 'Disconnected',
      status_transmission: 'Transmission',
    },
    message: {
      are_you_sure_you_want_to_delete_this_item: 'Are you sure you want to delete this item?',

      to_start_work_please_choose_device_for_bluetooth_scanning: 'To start work please choose device for bluetooth scanning',
      after_scan_list_of_available_data_will_be_shown_on_this_screen: 'After scan list of available data will be shown on this screen.',
      please_choose_device: 'Please choose device',
      please_choose_your_destination_device_in_the_next_screen: 'Note: Please choose your destination device again and press "Pair" in the next screen.',
      please_specify_your_clinic: 'Please specify your clinic',
      please_choose_patient: 'Please choose patient',
      please_choose_operator: 'Please choose operator',
      please_choose_operator_guidance: '* Operator selection is required if you use shared account.',
      please_specify: 'Please specify',
      patient_n_needs_items: 'Patient #{number} needs {missedItems}',
      data_has_been_successfully_uploaded: 'Data hss been successfully uploaded',
      teeth_map: 'Teeth map',
      submit_confirmation: 'Submit confirmation',
      extracted_data: 'Extracted data',
      application_ready_for_transmission: 'Application ready for transmission',
      proceed_with_device: 'Proceed with device.',

      upload_pexam_sheet_image: 'Upload P-Exam sheet image',
      pexam_image_successfully_uploaded: 'P-Exam image successfully uploaded.',
    },
  },
  ja: {
    action: { // Actions
      action: '操作',
      edit: '編集',
      delete: '削除',
      save: '保存',
      cancel: 'キャンセル',
      new_item: '新規追加',
      edit_item: '項目の編集',
      filter_by: '{key}で絞り込み',
      logout: 'ログアウト',

      scan_data: 'データ読み取り',
      teeth_evaluation: '検査結果',
      submit: '登録',
      reconnect: '再接続',
      terminate_transmission: '送信終了',
      discard: '閉じる',
      choose_device: '治療機の選択',
      confirm: '確認',
      show_tooth_map: '歯列図の表示',
      get_list_of_devices: '治療機一覧を取得',
      search_device_name: '{deviceName}を検索',
      submit_data: 'データ送信',
      debug_add_mock: '[DEBUG]モック追加',
    },
    menu: { // Menu items
      menu: '管理メニュー',
      clinic_groups: '医院グループ一覧',
      clinics: '医院一覧',
      users: 'ユーザー一覧',
      devices: '治療機一覧',
      patients: '患者一覧',
      treatment_sessions: '診療記録一覧',

      main: 'メインメニュー',
      scan_data: '治療機データの読み取り',
      teeth_evaluation: '検査結果',
      logout: 'ログアウト',
      goto_admin_site: '管理画面',
    },
    e: { // Entities
      clinic_group: '医院グループ',
      clinic: '医院',
      user: 'ユーザー',
      device: '治療機',
      patient: '患者',
      treatment_session: '診療記録',
    },
    p: { // Properties
      // Common
      name_of_person: '氏名',
      country: '国',
      region: '地域',
      email: 'メールアドレス',
      phone_number: '電話番号',
      remarks: '備考',
      clinic_group_id: '医院グループID',
      clinic_id: '医院ID',
      date: '日付',
      start_time: '開始',
      end_time: '終了',
      uuid: 'UUID',

      // Clinic groups
      name_of_clinic_group: '医院グループ名',
      name_pron_of_clinic_group: '医院グループ名ふりがな',

      // Clinic
      name_of_clinic: '医院名',
      name_pron_of_clinic: '医院名ふりがな',

      // Users
      role: '権限',

      // Devices
      serialnumber: '製造番号',
      status: '状態',
      name_of_device: '治療機名称',
      guidance_for_test_mode: '* この環境はテストモードで動作しています。',

      // Patients
      username: 'ユーザー名',
      password: 'パスワード',
      password_confirmation: 'パスワード（確認）',
      nickname: 'ニックネーム',
      birthdate: '誕生日',
      gender: '性別',
      registration_number: '診察券番号',
      accept_notification: '通知の受信',

      // Treatment sessions
      operator: '担当者',
      category: '種別',
      recorded_at: '日時',
      recorded_at_date: '日付',
      recorded_at_time: '時刻',
      periorank: 'P検ランク',

      total_laser: 'レーザー合計',
      total_liquid: '薬液合計',
      image_file: '画像ファイル',
      image: '画像',

      // Treatments
      tooth_number: '部位',
      duration: '所要時間',
      water: '薬液使用量',
      laser: 'レーザー使用',
    },
    v: { // Values
      yes: 'はい',
      no: 'いいえ',
      male: '男',
      female: '女',
      unassigned: '（未設定）',
      empty: '（記入なし）',

      // User roles
      role_SystemAdmin: 'システム管理者',
      role_ClinicGroupAdmin: '医院グループ管理者',
      role_ClinicAdmin: '医院管理者',
      role_Doctor: '医師',
      role_Hygienist: '歯科衛生士',
      role_PurchaseStaff: '購買担当者',
      role_OrderStaff: '受注担当者',
      role_CallCenter: 'コールセンター',
      role_SharedAccount: '共有アカウント',

      // Device statuses
      status_InStock: '在庫保管中',
      status_InService: '稼働中',

      // Treatment session categories
      category_PeriodonticCare: '歯周治療',
      category_Maintenance: 'メンテナンス',

      // BLE
      ble_parameter_name: 'Parameter name',
      ble_value: 'Value',

      // Connection statuses
      status_waiting_pairing: 'ペアリング待機中',
      status_pairing: 'ペアリング中',
      status_paired: 'ペアリング済',
      status_restoring_connection: '再接続中',
      status_disconnected: '未接続',
      status_transmission: '転送',
    },
    message: {
      are_you_sure_you_want_to_delete_this_item: 'この項目を削除してもよろしいですか？',

      to_start_work_please_choose_device_for_bluetooth_scanning: 'データ読み取りを行う治療機を選択してください',
      after_scan_list_of_available_data_will_be_shown_on_this_screen: '治療機の検索後、接続可能な治療機の一覧が表示されます。',
      please_choose_device: '治療機を選択してください',
      please_choose_your_destination_device_in_the_next_screen: '※次の画面では、接続先の治療機を再度選択し「ペア設定」を押してください。',
      please_specify_your_clinic: '医院を選択してください',
      please_choose_patient: '患者を選択してください',
      please_choose_operator: '担当者を選択してください',
      please_choose_operator_guidance: '※ 共有アカウントを使用している場合は担当者選択が必要となります。',
      please_specify: '以下の項目をご記入ください',
      patient_n_needs_items: '患者 #{number}の{missedItems}',
      data_has_been_successfully_uploaded: 'データのアップロードが完了しました',
      teeth_map: '歯列図',
      submit_confirmation: '登録確認',
      extracted_data: '読取結果',
      application_ready_for_transmission: '接続完了',
      proceed_with_device: '治療機からデータを送信してください。',

      upload_pexam_sheet_image: 'P検用紙画像のアップロード',
      pexam_image_successfully_uploaded: 'P検用紙画像のアップロード完了',
    },
  },
};
