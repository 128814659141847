<template>
  <div>
    <div v-if="!isProduction" class="primary--text text-sm-h4 text-center"
      style="position:fixed; left:10px; bottom:10px; z-index:10000;">{{ $t('p.guidance_for_test_mode') }}</div>
    <div style="position:absolute; right:0; bottom:0; color:#aaaaaa; width:200; height:30;">ver:240722a</div>
    <v-card
      width="100%"
      class="mx-auto overflow-hidden">
      <!--  -->
      <v-app-bar
        color="blue lighten-1"
        dark>
        <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
        <v-toolbar-title>{{ $ls.get('user') != null ? $ls.get('user_display_name') : "-" }} / {{ ( chosenClinic != null )? chosenClinic.name : "" }}</v-toolbar-title>
        <v-row class="d-flex justify-end">
          <v-btn
            elevation="0"
            color="rgb(66, 165, 245)"
            class="mr-5"
            v-if="$route.name === 'Main'">
            {{ $t('p.status') }}: {{ deviceStatus.text }}
            <!-- Ready v -->
            <v-icon v-if="deviceStatus.statusCode === 0">mdi-access-point-check</v-icon>
            <!-- Connecting v -->
            <v-icon v-if="deviceStatus.statusCode === 1">mdi-bluetooth-audio</v-icon>
            <!-- Connected  v -->
            <v-icon v-if="deviceStatus.statusCode === 5">mdi-bluetooth</v-icon>
            <!-- Restoring connection v -->
            <v-icon v-if="deviceStatus.statusCode === 2">mdi-backup-restore</v-icon>
            <!-- connection lost v -->
            <v-icon v-if="deviceStatus.statusCode === 3">mdi-bluetooth-off</v-icon>
            <!-- transmitting data -->
            <v-icon v-if="deviceStatus.statusCode === 4">mdi-bluetooth-transfer</v-icon>
          </v-btn>
          <v-btn outlined
            v-if="$route.name === 'Main'"
            @click="choseDevice()">
            {{ $t('action.choose_device') }}
            <v-icon>mdi-chip</v-icon>
            <v-icon>mdi-bluetooth-connect</v-icon>
          </v-btn>
        </v-row>
      </v-app-bar>
    </v-card>
      <v-navigation-drawer
        v-model="drawer"
        absolute
        temporary>
        <v-list
          nav
          dense>
          <v-list-item-group
            v-model="group"
            active-class="deep-purple--text text--accent-4">
            <v-list-item @click="$router.push({ name: 'Menu' })">
              <v-list-item-icon>
                <v-icon>mdi-bluetooth-transfer</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t('menu.main') }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout()">
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t('menu.logout') }}</v-list-item-title>
            </v-list-item>

            <v-list-item @click="openAdminSite()">
              <v-list-item-icon>
                <v-icon>mdi-account-cog</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t('menu.goto_admin_site') }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>

        <v-divider></v-divider>

        <v-list>
          <v-list-item>
            <v-select
              v-model="languageValue"
              :items="languageValues"
              item-text="label"
              item-value="value"
              prepend-inner-icon="mdi-earth"
              menu-props="auto"
              hide-details
              single-line
              @change="onChangeLanguage"
            ></v-select>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { EventBus } from './EvenBus/event-bus.js';

export default {
  name: 'Menubar',
  props: {
    language: {
      type: String,
    },
    languageValues: {
      type: Array,
    },
  },
  data() {
    return {
      isProduction: ( typeof(process) != "undefined" )? process.env.NODE_ENV == 'production' : false ,
      group: null,
      drawer: false,
      chosenClinic: null,
      patientReminder: false,
      deviceStatus: {
        text: '',
        statusCode: 0,
      },
      languageValue: null,
      // icon animation related
      show: true,
      fadeInDuration: 1000,
      fadeOutDuration: 1000,
      maxFadeDuration: 1500,
      stop: true,
    }
  },
  computed: mapState(['currentClinic']),
  created () {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'setCurrentClinic') {
        // === On change from null to clinic object settting up local value
        if (state.currentClinic !== null) {
          // this.chosenClinic = this.$store.getters.getCurrentClinic.name;
          this.chosenClinic = this.$ls.get('currentClinic');
        } else {
          console.log('Error: failed tu update currentClinic');
        }
        // ===
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  mounted() {
    if(this.$ls.get('currentClinic') !== null) {
      this.chosenClinic = this.$ls.get('currentClinic');
    }
    EventBus.$on('remind-patient', () => {
      this.patientReminder = true;
      this.stop = false;
    });
    EventBus.$on('remind-patient-off', () => {
      this.patientReminder = false;
      this.stop = true;
    });
    EventBus.$on('change-device-status', (status) => {
      this.deviceStatus.text = status.text;
      this.deviceStatus.statusCode = status.statusCode;
    });
    this.languageValue = this.language;
  },
  methods: {
    logout() {
      this.$ls.clear();
      this.$router.push({ path: '/' });
    },
    choseDevice() {
      EventBus.$emit('chose-device-dialog');
    },
    onChangeLanguage() {
      // notify language change to the parent component
      this.$emit('change-language', this.languageValue);
    },
    openAdminSite(){
      window.open('https://perimiru.com/admin', '_blank').focus();
    },
  },
  watch: {
    language(val) {
      this.languageValue = val;
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
