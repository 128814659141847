import Vue from 'vue'
import Notifications from 'vue-notification';
import VueLocalStorage from 'vue-ls';
import axios from 'axios';

import store from './store/index.js';
import App from './App.vue'
import i18n from './i18n';
import vuetify from './plugins/vuetify';
import '@/plugins/dayjs';
import '@babel/polyfill'
import router from './router';

Vue.prototype.$http = axios;
// const options = {
//   namespace: 'luke_data_', options
// };

Vue.use(VueLocalStorage);
Vue.use(Notifications);

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
