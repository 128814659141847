import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentClinic: {},
  },
  getters: {
    getCurrentClinic: state => {
      return state.currentClinic;
    },
  },
  mutations: {
    setCurrentClinic (state, payload) {
      state.currentClinic = payload;
    },
  },
  actions: {
  },
  modules: {
  },
});
