<template>
  <v-app>
    <!-- <v-navigation-drawer app>

    </v-navigation-drawer>

    <v-app-bar app color="blue lighten-1">
    </v-app-bar> -->
    <MenuBar
      v-if="$ls.get('api_token') !== null"
      :language="language"
      :languageValues="languageValues"
      @change-language="onChangeLanguage">
    </MenuBar>

    <!-- Sizes your content based upon application components -->
    <v-main>

      <!-- Provides the application the proper gutter -->
      <v-container fluid>

        <!-- If using vue-router -->
        <router-view></router-view>
        <!-- <Login /> -->
      </v-container>
    </v-main>
    <!-- <v-footer app> -->
      <!-- -->
    <!-- </v-footer> -->
    <!-- <div id="app">
      <HelloWorld msg="Connectivity page"/>
    </div> -->
  </v-app>
</template>

<script>
// import HelloWorld from './views/HelloWorld.vue'
import MenuBar from './MenuBar.vue';

export default {
  name: 'App',
  components: {
    MenuBar,
  },
  data: () => ({
    language: 'ja',
    languageValues: [
      { label: 'English', value: 'en' },
      { label: '日本語', value: 'ja' },
    ],
  }),
  beforeMount() {
    this.setLanguage(this.$ls.get('language') || this.language);
  },
  methods: {
    setLanguage(languageValue) {
      this.$i18n.locale = languageValue;
      this.$vuetify.lang.current = languageValue;
    },
    onChangeLanguage(languageValue) {
      this.language = languageValue;
      this.$ls.set('language', languageValue);
      this.setLanguage(languageValue);
    },
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
