<template>
  <div>
    <!-- <notifications group="notification" position="top center" style="z-index: 9001;"/> -->
    <!-- ==== Busy overlay -->
    <v-overlay absolute :value="overlay" style="z-index: 9000;">
      <v-progress-circular :value="calculatedCharacteristicProgress()" size="64"></v-progress-circular>
    </v-overlay>
    <!-- ==== Main view -->
    <v-card class="mx-auto" tile>
      <!-- ==== Validation warning -->
      <v-alert id="validation_alert" v-if="showValidationAlert" border="left" elevation="11" text type="warning">{{
      $t('message.please_specify')
    }}: {{ missedInput.join('; ') }}</v-alert>
      <!-- ==== Successful upload notification -->
      <v-snackbar :timeout="3000" :value="completeUpload" absolute top centered color="success" outlined>{{
      $t('message.data_has_been_successfully_uploaded')
    }}</v-snackbar>
      <!-- ==== Clinic choice dialog -->
      <v-dialog v-model="clinicDialog" persistent scrollable max-width="350">
        <v-card>
          <v-card-title class="headline">{{ $t('message.please_specify_your_clinic') }}</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-radio-group v-model="chosenClinicIndex" column>
              <v-radio v-for="(singleClinic, index) in $ls.get('clinics')" :key="index" :label="singleClinic.name"
                :value="index"></v-radio>
            </v-radio-group>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="confirmClinic()">{{ $t('action.confirm') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- ==== Device -->
      <v-dialog v-model="deviceDialog" scrollable max-width="650">
        <v-card>
          <v-card-title class="headline">{{ $t('message.please_choose_device') }}</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row class="px-5">
              <v-col class="align-self-center flex-grow-0 flex-shrink-1">
                <p class="font-weight-bold text-no-wrap mb-0">{{ $t('e.device') }}</p>
              </v-col>
              <v-col class="align-self-center flex-grow-1 flex-shrink-0">
                <!-- Selectbox to choose registered device on db -->
                <v-select :items="fetchedDevices" item-text="name" return-object v-model="chosenDevice"
                  :label="$t('message.please_choose_device')" prepend-icon="mdi-bluetooth-connect" dense hide-details
                  outlined></v-select>
              </v-col>
              <v-col class="align-self-center flex-grow-0 flex-shrink-1">
                <!-- Start to scan BLE devices. (web-browser will be open device list)  -->
                <v-btn color="blue lighten-1 white--text" class="align-self-center"
                  :disabled="chosenDevice === undefined" @click="scanBLE_Data()">{{ $t('action.scan_data') }}</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col style="text-align: right;">
                {{ $t('message.please_choose_your_destination_device_in_the_next_screen') }}
              </v-col>
            </v-row>
            <v-row>
              <v-col class="align-self-center flex-grow-0 flex-shrink-1">
                <!-- 再接続 -->
                <v-btn color="blue lighten-1 white--text" class="align-self-center"
                  :disabled="bluetoothDevice === undefined" @click="ReConnection()">{{ $t('action.reconnect') }}</v-btn>
              </v-col>
              <v-col class="align-self-center flex-grow-0 flex-shrink-1">
                <!-- 送信終了 -->
                <v-btn color="blue lighten-1 white--text" class="align-self-center"
                  :disabled="bluetoothDevice === undefined" @click="ManualTermination()">{{
      $t('action.terminate_transmission')
    }}</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="deviceDialog = false">{{ $t('action.discard') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- === Slider for each (loaded) treatment -->
      <v-window v-model="onboarding">
        <v-window-item v-if="aggregatedTreatments.length == 0"> 治療器でデータ転送を行うと、ここに送信フォームが表示されます。 </v-window-item>
        <v-window-item v-for="(treatment, treatmentIndex) in aggregatedTreatments" :key="treatmentIndex">
          <v-card elevation="5">
            <!-- ==== Slide window for one patient -->
            <!-- ==== Teeth map -->
            <v-row justify="center">
              <!-- v-model="treatment.showMap" -->
              <v-dialog v-model="treatment.showMap" max-width="360">
                <v-card>
                  <v-card-title class="headline">{{ $t('message.teeth_map') }}</v-card-title>
                  <v-divider></v-divider>
                  <div id="mapcontainer">
                    <TeethMap :treateadTeeth="treatment.scannedData" :mapIndex="treatmentIndex"></TeethMap>
                    <!-- TeehMap component will be mounted here programmatically -->
                  </div>
                </v-card>
              </v-dialog>
              <v-dialog v-model="treatment.submitConfirmation" max-width="360">
                <v-card>
                  <v-card-title class="headline">{{ $t('message.submit_confirmation') }}</v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-row class="mt-4">
                      <v-col class="">
                        <strong> {{ (treatment.patient && treatment.patient.name) || '(患者 未選択)' }} </strong>
                        に送信でよろしいですか？<br />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="d-flex justify-center align-self-center">
                        <v-btn color="blue lighten-1 white--text" v-if="aggregatedTreatments.length !== 0"
                          class="align-self-center mt-4 mb-4" :disabled="aggregatedTreatments.length === 0"
                          @click="submitData(treatmentIndex)">{{ $t('action.submit') }}</v-btn>
                        <v-btn color="grey darken-1 white--text" v-if="aggregatedTreatments.length !== 0"
                          class="align-self-center ml-4 mt-4 mb-4" :disabled="aggregatedTreatments.length === 0"
                          @click="hideSubmitConfirmation()">{{ $t('action.cancel') }}</v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-row>
            <!-- ==== Chose patient -->
            <v-row style="height: 75px;" class="px-5">
              <v-col class="align-self-center flex-grow-0 flex-shrink-1">
                <p class="font-weight-bold mb-0" style="white-space: nowrap">{{ $t('e.patient') }} #{{ onboarding + 1 }}
                </p>
              </v-col>
              <v-col class="align-self-center flex-grow-1 flex-shrink-0">
                <v-autocomplete class="align-self-center" :items="patientsList" item-text="name" item-value="_id"
                  return-object v-model="treatment.patient" :label="$t('message.please_choose_patient')"
                  prepend-icon="mdi-card-account-details-outline" dense hide-details outlined></v-autocomplete>
              </v-col>
            </v-row>
            <!-- ==== Treatment Category & Operator -->
            <v-row style="height: 75px;" class="px-5">
              <!-- class="align-self-center flex-grow-0 flex-shrink-1" -->
              <v-col class="align-self-center flex-grow-0 flex-shrink-1">
                <p class="font-weight-bold mb-0 text-left" style="white-space: nowrap">{{ $t('p.category') }}:</p>
              </v-col>
              <v-col cols="4" class="align-self-center d-flex justify-space-around">
                <v-radio-group class="pa-0 d-flex justify-space-around" v-model="treatment.treatmentCategory" row>
                  <v-radio v-for="category in treatmentCategoryList" :label="category.name" :value="category"
                    :key="category._id"></v-radio>
                  <!-- <v-radio :label="$t('v.category_PeriodonticCare')" value="PeriodonticCare"></v-radio>
                  <v-radio :label="$t('v.category_Maintenance')" value="Maintenance"></v-radio> -->
                </v-radio-group>
              </v-col>
              <!-- 共有アカウントでログイン時、ユーザー選択を表示！ -->
              <v-col v-if="!lockOperatorChoice" cols="6" class="align-self-center">
                <v-row>
                  <p class="font-weight-bold mb-0 align-self-center mr-3">{{ $t('p.operator') }}: </p>
                  <v-select class="align-self-center" :items="operatorsList" :disabled="lockOperatorChoice"
                    item-text="user_name" return-object v-model="treatment.operator"
                    :label="$t('message.please_choose_operator')" prepend-icon="mdi-card-account-details-outline" dense
                    hide-details outlined>
                  </v-select>
                  <label style="font-size:70%"> {{ $t('message.please_choose_operator_guidance') }} </label>
                </v-row>
              </v-col>
            </v-row>
            <!-- ==== Table -->
            <!-- 読み取り結果-->
            <v-row v-if="treatment.scannedData.length !== 0">
              <v-col style="overflow: auto">
                <!-- Extracted data and tooth map -->
                <v-row class="px-5 my-2">
                  <v-col class="pb-0 align-self-center flex-shrink-0 flex-grow-0">
                    <p class="font-weight-bold mx-auto mb-0 text-left" style="white-space: nowrap">{{
      $t('message.extracted_data')
    }}</p>
                  </v-col>
                  <v-col class="pb-0 align-self-center d-flex justify-start">
                    <v-btn color="blue lighten-1 white--text" small :disabled="treatment.scannedData.length === 0"
                      @click="showToothMap()">{{ $t('action.show_tooth_map') }}</v-btn>
                    <!-- showToothMap(treatmentIndex) -->
                  </v-col>
                  <!-- Treatment session details -->
                  <v-col class="pb-0 align-self-center">
                    <p class="font-weight-bold mx-auto mb-0 text-left">{{ $t('p.date') }}: {{ treatment.header.date }}
                    </p>
                  </v-col>
                  <v-col class="pb-0 align-self-center">
                    <v-row>
                      <p class="font-weight-bold mx-auto mb-0 text-left">{{ $t('p.start_time') }}: {{
      treatment.header.startTime
    }}</p>
                    </v-row>
                    <v-row>
                      <p class="font-weight-bold mx-auto mb-0 text-left">{{ $t('p.end_time') }}: {{
      treatment.header.endTime
    }}</p>
                    </v-row>
                  </v-col>
                  <v-col class="pb-0 align-self-center">
                    <p class="font-weight-bold mx-auto mb-0 text-left">{{ $t('p.total_laser') }}: {{
      treatment.header.totalLaserDuration
    }}</p>
                  </v-col>
                  <v-col class="pb-0 align-self-center">
                    <p class="font-weight-bold mx-auto mb-0 text-left">{{ $t('p.total_liquid') }}: {{
      treatment.header.totalLiquid
    }}</p>
                  </v-col>
                </v-row>
                <!-- 442 読取結果データテーブル -->
                <v-simple-table class="overflow-y-auto" :key="tableReRenderCounter" :height="`${$vuetify.breakpoint.height - 434 - (showValidationAlert === true
      ? computedAlertHeight2 : 0)}px`" fixed-header>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center">{{ $t('p.tooth_number') }}</th>
                        <!-- <th class="text-center">Average of</th> -->
                        <th class="text-center">{{ $t('p.duration') }} (s)</th>
                        <th class="text-center">{{ $t('p.water') }} (mL)</th>
                        <th class="text-center">{{ $t('p.laser') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(tooth, index) in treatment.scannedData" :key="index">
                        <td class="text-center">{{ tooth.number == -1 ? `選択なし` : tooth.number }}</td>
                        <td class="text-center">{{
      tooth.duration <= 60 ? `${tooth.duration}s` : `${Math.floor(tooth.duration / 60)}m
                            ${tooth.duration % 60}s` }}</td>
                        <td class="text-center">{{ tooth.water }}</td>
                        <td class="text-center">
                          <v-icon color="light-green accent-4" v-if="tooth.laser === true">
                            mdi-checkbox-marked-circle-outline</v-icon>
                          <v-icon v-else>mdi-close-circle-outline</v-icon>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card>
        </v-window-item>
      </v-window> <!-- finish: slider for each treatments -->
      <v-container style="text-align: center;">
        <!-- Submit after edit/confirm results. -->
        <v-btn color="blue lighten-1 white--text" v-if="aggregatedTreatments.length !== 0" class="mt-4 mb-4"
          :disabled="aggregatedTreatments.length === 0" @click="showSubmitConfirmation()">{{ $t('action.submit')
          }}</v-btn>
      </v-container>
      <!-- ==== Not chosen device hint -->
      <v-card v-if="chosenDevice === undefined && bluetoothDevice === undefined" class="mx-auto mb-4" max-width="344">
        <v-card-text>
          <p class="display-1 text--primary">{{ $t('message.to_start_work_please_choose_device_for_bluetooth_scanning')
            }}</p>
          <div class="text--primary">
            {{ $t('message.after_scan_list_of_available_data_will_be_shown_on_this_screen') }}
            <br>
          </div>
        </v-card-text>
      </v-card>
      <!-- ==== Ready to transmit hint -->
      <v-card v-if="readyForData && hasAggregatedTreatmentLength" class="mx-auto mb-4" max-width="344">
        <v-card-text>
          <p class="display-1 text--primary">{{ $t('message.application_ready_for_transmission') }}</p>
          <div class="text--primary">
            {{ $t('message.proceed_with_device') }}
            <br>
          </div>
        </v-card-text>
      </v-card>
      <!-- ==== Slider controls -->
      <v-card-actions v-if="aggregatedTreatments.length !== 0" class="justify-space-between">
        <v-btn text @click="prev">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-item-group v-model="onboarding" class="text-center" mandatory>
          <v-item v-for="n in aggregatedTreatments.length" :key="`btn-${n}`" v-slot="{ active, toggle }">
            <v-btn :input-value="active" icon @click="toggle">
              <v-icon>mdi-record</v-icon>
            </v-btn>
          </v-item>
        </v-item-group>
        <v-btn text @click="next">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-container style="text-align: center;">
      <!-- Debug: Add a mock treatment. -->
      <v-btn color="orange lighten-1 white--text" v-if="!isProduction" class="mt-4 mb-4" @click="debugAddMock()">{{
      $t('action.debug_add_mock') }}</v-btn>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import { EventBus } from '../EvenBus/event-bus.js';
// eslint-disable-next-line
import TeethMap from './TeethMap.vue';
import Toasted from 'vue-toasted';
// eslint-disable-next-line
import Vue from 'vue';
// import { Promise } from 'q';

Vue.use(Toasted);// this.$toasted.error(msg); みたいな使い方をする。

export default {
  name: 'Main',
  components: {
    // eslint-disable-next-line
    TeethMap,
  },
  computed: {
    ...mapState(['currentClinic']),
    patientsList() {
      return (this.patients ? this.patients : []);
    },
    singleTreatment() {
      const ind = this.onboarding > this.aggregatedTreatments.length - 1
        ? this.aggregatedTreatments.length - 1
        : this.onboarding;
      return this.aggregatedTreatments[ind].scannedData;
    },
    computedAlertHeight() {
      // 16 is margin bottom defined as default value of .v-alert class from Vutify
      if (document.querySelector('#validation_alert') !== null) {
        return document.querySelector('#validation_alert').offsetHeight + 16;
      }
      return 0;
    },
    computedOperatorsList() {
      return this.operatorsList;
    },
    hasAggregatedTreatmentLength() {
      return this.aggregatedTreatments.length === 0;
    },
  },
  created() {
    // console.log('Main menu created');
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'setCurrentClinic') {
        // console.log(`Updating to ${state.currentClinic}`);
        // console.log(state.currentClinic);
        // === On change from null to clinic object settting up local value
        if (state.currentClinic !== null) {
          this.m_chosenClinic = this.$store.getters.getCurrentClinic;
          this.getPatientsList(this.m_chosenClinic._id);
        } else {
          console.log('Error: failed tu update currentClinic');
        }
        // ===
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  mounted() {
    EventBus.$on('chose-device-dialog', () => {
      this.deviceDialog = true;
    });
    // === Load local storage values
    const currentClinic = this.$ls.get('currentClinic');
    const clinics = this.$ls.get('clinics');

    if (clinics.length > 1) {
      if (currentClinic === null) {
        this.clinicDialog = true;
      } else {
        this.chosenClinic = currentClinic;
        this.initDataLists();
      }
    } else {
      const expire = 24 * 60 * 60 * 1000; // session expires after 24 hours
      this.$ls.set('currentClinic', clinics[0], expire);
      this.$store.commit('setCurrentClinic', clinics[0]);
      this.chosenClinic = clinics[0];
      this.initDataLists();
      // offer user to chose ddevice: preliminary step
      this.deviceDialog = true;
    }
    EventBus.$emit('change-device-status', { statusCode: 0, text: this.$t('v.status_waiting_pairing') });
  },
  data() {
    return {
      m_chosenClinic: {},
      patients: null,
      clinicDialog: false,
      chosenClinic: '',
      chosenClinicIndex: -1,
      chosenPatient: undefined,
      chosenDevice: undefined,
      treatmentCategory: '',
      treatmentCategoryList: [],
      fetchedDevices: [], // real list of devices fetched from API
      overlay: false,
      scannedData: [],
      showValidationAlert: false,
      validationReport: [],
      completeUpload: false,
      missedInput: [],
      // test
      deviceService: undefined,
      // slider related
      onboarding: 0,
      confirmSubmissionDialog: false,
      // TODO: for real case preapre empty initial value
      aggregatedTreatments: [],
      isProduction: process.env.NODE_ENV == 'production',
      bluetoothDevice: undefined,
      sliderLength: 0,
      deviceDialog: false,
      teethMapInstances: [],
      computedAlertHeight2: 0,
      tableReRenderCounter: 0,
      //
      PrimaryService: {},
      promiseDelay: 400, // TODO: remove later
      confirmationCharacteristic: {}, // set value by service.getCharacteristic()
      deviceScanResult: {
        date: null,
        startTime: null,
        endTime: null,
        totalLaserDuration: null,
        totalLiquid: null,
        totalTeeth: null,
        treats: [],
      },
      readyForData: false,
      numberOfTransmissions: 0,
      characteristicProgress: 0, // 進捗表示用の変数。 Current progress rate = "characteristicProgress/maxStep"  画面上部のv-progress-circularで表現される。
      maxStep: 11,
      lockOperatorChoice: false,
      operatorsList: [],
      // tmp
      disconnectionCounter: 0,
    }
  },
  methods: {
    next() {
      this.onboarding = this.onboarding + 1 === this.sliderLength
        ? 0
        : this.onboarding + 1
    },
    prev() {
      this.onboarding = this.onboarding - 1 < 0
        ? this.sliderLength - 1
        : this.onboarding - 1
    },
    toast_info(message) {
      if (!this.isProduction) {
        this.$toasted.show("info:" + message, { duration: 5000, type: 'info' });
      }
      console.debug(message)
    },
    toast_success(message) {
      this.$toasted.show(message, { duration: 5000, type: 'success' });
    },
    toast_error(message) {
      this.$toasted.show(message, { duration: 5000, type: 'error' });
    },
    initDeviceScanResult() {
      this.deviceScanResult = {
        date: null,
        startTime: null,
        endTime: null,
        totalLaserDuration: null,
        totalLiquid: null,
        totalTeeth: null,
        treats: [],
      }
    },
    initDataLists() {
      this.getPatientsList(this.$ls.get('currentClinic')._id);
      this.getDevices(this.$ls.get('currentClinic')._id);
      this.gettreatmentCategoryList();
      if (this.$ls.get('user_role') === 'SharedAccount') {
        // 共有アカウントの場合、担当者選択が必要になるため、担当者候補ユーザーを取得する。
        this.getOperatorsList();
        this.toast_info("共有アカウントでログインしているため、担当者一覧を取得しました。")
      } else {
        // SharedAccount以外でログインしている時！
        // bubble側で定義されたUser typeのオブジェクトが入るはずなので、ログインユーザーのUser typeをそのまま挿入。
        this.operatorsList.push(this.$ls.get('user'));
        this.lockOperatorChoice = true;
      }
      this.toast_info("画面初期化が完了しました")
    },
    getPatientsList(clinicID) {
      var GENDER_LABEL = { 0: this.$t('v.male'), 1: this.$t('v.female') }
      axios({
        method: 'get',
        url: `${process.env.VUE_APP_API_BASEURL}/obj/user_patients`,
        headers: {
          Accept: '*/*',
        },
        params: {
          api_token: this.$ls.get('api_token'),
          constraints: JSON.stringify([
            { "key": "clinic_id", "constraint_type": "equals", "value": clinicID },
            { "key": "is_deleted", "constraint_type": "not equal", "value": "true" }
          ]),
        },
      })
        .then((response) => {
          console.log("patients has got");
          console.log(response.data.response.results);
          if (this.patients == null) { // 非同期でなんだかんだ多重ロードされるのを防ぐ。最初の1回だけ採用。
            this.patients = response.data.response.results;
            this.patients.forEach(patient => {
              if ((typeof patient.name) === "undefined") {
                patient.name = "[氏名未設定の患者]";
              }
              // if( (typeof patient.birthdate) != "undefined" ){ // 一旦生年は表示しないまま進めよう
              //   patient.name +=  ` (生年:${patient.birthdate.substr(0,4)})`;
              // }
              if ((typeof patient.branch_id_on_clinic) != "undefined") {
                patient.name = `ID${patient.branch_id_on_clinic} [${patient.registration_number}] ${patient.name} (${GENDER_LABEL[patient.gender]}) `;
              }
            });
            this.toast_info("患者リストの取得に成功しました。")
          }
        })
        .catch((error) => {
          // TODO: Notification should be there
          console.log(error.message);
          console.log(error.data);
          console.log(error.response);
          this.toast_error("患者リストの取得に失敗")
        }); // end of axios
    },
    confirmClinic() {
      if (this.chosenClinicIndex !== -1) {
        const clinic = this.$ls.get('clinics')[this.chosenClinicIndex];
        this.chosenClinic = clinic;
        const expire = 24 * 60 * 60 * 1000; // session expires after 24 hours
        this.$ls.set('currentClinic', clinic, expire);
        this.$store.commit('setCurrentClinic', clinic);
        this.initDataLists();
        this.clinicDialog = false;
        this.deviceDialog = true;
      }
    },
    confirmPatient() {
      EventBus.$emit('remind-patient-off');
    },
    getDevices(clinicID) {
      axios({
        method: 'get',
        url: `${process.env.VUE_APP_API_BASEURL}/obj/devices`,
        headers: {
          Accept: 'application/json',
        },
        params: {
          api_token: this.$ls.get('api_token'),
          constraints: JSON.stringify([
            { "key": "clinic_id", "constraint_type": "equals", "value": clinicID },
            { "key": "is_deleted", "constraint_type": "not equal", "value": "true" }
          ]),
        },
      })
        .then((response) => {
          console.log(response.data.response.results);
          this.fetchedDevices = response.data.response.results;
          this.toast_info("治療器リストの取得に成功しました。")
        })
        .catch((error) => {
          // TODO: Notification should be there
          console.log(error.message);
          console.log(error.data);
          console.log(error.response);
          this.toast_error("治療器リストの取得に失敗しました。")
        }); // end of axios
    },
    gettreatmentCategoryList() {
      axios({
        method: 'get',
        url: `${process.env.VUE_APP_API_BASEURL}/obj/treatment_categories`,
        headers: {
          Accept: 'application/json',
        },
        params: {
          api_token: this.$ls.get('api_token'),
        },
      })
        .then((response) => {
          console.log(response.data.response.results);
          this.treatmentCategoryList = response.data.response.results;
          this.toast_info("治療種別リストの取得に成功しました")
        })
        .catch((error) => {
          // TODO: Notification should be there
          console.log(error.message);
          console.log(error.data);
          console.log(error.response);
          this.toast_error("治療種別リストの取得に失敗しました")
        }); // end of axios
    },
    getLatestTreatmentSessionID() {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `${process.env.VUE_APP_API_BASEURL}/obj/treatment_sessions`,
          headers: {
            Accept: 'application/json',
          },
          params: {
            api_token: this.$ls.get('api_token'),
            limit: 1,
            sort_field: 'id',
            descending: true,
          },
        })
          .then((response) => {
            // console.log(response.data.response.results);
            if (response.data.response.results.length == 0) {
              // まだ治療が一つもない場合、1からID開始
              resolve(1);
            } else {
              const latestId = response.data.response.results[0].id;
              resolve(latestId);
            }
          })
          .catch((error) => {
            // TODO: Notification should be there
            console.error(error.message);
            console.log(error.data);
            console.log(error.response);
            this.toast_error("付番失敗")
            reject();
          }); // end of axios
      });
    },
    getLatestTreatmentID() {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `${process.env.VUE_APP_API_BASEURL}/obj/treatments`,
          headers: {
            Accept: 'application/json',
          },
          params: {
            api_token: this.$ls.get('api_token'),
            limit: 1,
            sort_field: 'id',
            descending: true,
          },
        })
          .then((response) => {
            console.log(response.data.response.results);
            if (response.data.response.results.length == 0) {
              // まだ治療が一つもない場合、1からID開始
              resolve(1);
            } else {
              const latestId = response.data.response.results[0].id;
              resolve(latestId);
            }
          })
          .catch((error) => {
            // TODO: Notification should be there
            console.error(error.message);
            console.log(error.data);
            console.log(error.response);
            this.toast_error("付番失敗")
            reject();
          }); // end of axios
      });
    },
    // handling reconnection
    onDisconnected() {
      this.disconnectionCounter += 1;
      console.log(`> Disconnected ${this.disconnectionCounter} times`);
      console.log('!!! === Disconnected !!!');
      EventBus.$emit('change-device-status', { statusCode: 3, text: this.$t('v.status_disconnected') });
      setTimeout(() => {
        EventBus.$emit('change-device-status', { statusCode: 2, text: this.$t('v.status_restoring_connection') });
      }, 2000);
      const toTry = () => {
        this.time('Connecting to Bluetooth Device... ');
        // return self.bluetoothDevice.gatt.connect();
        return this.connectDevice(this.bluetoothDevice);
      };
      const success = (server) => {
        this.connectToserver(server);
        console.log('> Bluetooth Device connected. Try disconnect it now.');
        this.toast_info("機器との接続に成功しました")
      };
      const fail = () => {
        this.time('Failed to reconnect.');
        this.toast_error("機器との接続に失敗しました")
        EventBus.$emit('change-device-status', { statusCode: 3, text: this.$t('v.status_disconnected') });
      };
      this.exponentialBackoff(/*self,*/ 3 /* max retries */, 2 /* seconds delay */,
        toTry,
        success,
        fail);
    },
    // 指数関数的バックオフアルゴリズム
    exponentialBackoff(/*self,*/ max, delay, toTry, success, fail) {
      toTry().then(result => success(result))
        .catch(() => {
          if (max === 0) {
            return fail();
          }
          this.time('Retrying in ' + delay + 's... (' + max + ' tries left)');
          setTimeout(function () {
            this.exponentialBackoff(--max, delay + 2, toTry, success, fail);
          }, delay * 1000);
        });
    },
    time(text) {
      console.log('[' + new Date().toJSON().substr(11, 8) + '] ' + text);
    },
    ReConnection() {
      this.bluetoothDevice.gatt.connect()
        .then(server => this.connectToserver(server));
    },
    // ======================
    // This func is executed after choose registered device from 'devices' table on DB.
    // BLE related examples https://googlechrome.github.io/samples/web-bluetooth/index.html
    async scanBLE_Data() {
      console.log('Requesting Bluetooth Device...');
      EventBus.$emit('change-device-status', { statusCode: 1, text: this.$t('v.status_pairing') });
      navigator.bluetooth.requestDevice(
        {
          // for scan everything
          // acceptAllDevices: true, // for debug
          // optionalServices: [
          //   '7c1be67c-0000-9fb8-1ae2-821d64ab0bb6',
          //   '2014f2d4-1159-84c4-7500-0ba1b4cac3e3',
          //   'd68c0001-a21b-11e5-8cb8-0002a5d5c51b',
          //   '7c1be67c-0010-9fb8-1ae2-821d64ab0bb6',
          // ],
          // search by name
          filters: [
            { name: this.chosenDevice.name },
            {
              services: [
                '7c1be67c-0000-9fb8-1ae2-821d64ab0bb6',
                '7c1be67c-0010-9fb8-1ae2-821d64ab0bb6',
              ],
            },
          ],
          optionalServices: ['7c1be67c-0010-9fb8-1ae2-821d64ab0bb6'],
        }
      ).then(device => {
        this.bluetoothDevice = device;
        this.bluetoothDevice.addEventListener('gattserverdisconnected', this.onDisconnected);
        console.log('Connecting to GATT Server...');
        // device.gatt.connect()
        //   .then(server => this.connectToserver(server));
        this.connectDevice(device)
          .then(server => {
            console.log('Run connectToserver...', server);
            this.connectToserver(server)
          })
          .catch(error => {
            console.log('Error in connectDevice', error);
          });
      });
    },
    connectDevice(device) {
      this.bluetoothDevice = device;
      console.log('Run device.gatt.connect()', device);
      return device.gatt.connect();
      // .then(server => this.connectToserver(server));
    },
    // 現在時刻をcharactaristic
    getTimeArray() {
      // 現在時刻の各成分をまとめる。
      let d = new Date();
      let ye = new Intl.DateTimeFormat('en', { year: '2-digit' }).format(d);
      let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
      let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
      let hh = new Intl.DateTimeFormat('en', { hour: '2-digit', hour12: false }).format(d);
      let mm = new Intl.DateTimeFormat('en', { minute: '2-digit' }).format(d);
      mm = mm.length === 1 ? `0${mm}` : mm;
      let ss = new Intl.DateTimeFormat('en', { second: '2-digit' }).format(d);
      ss = ss.length === 1 ? `0${ss}` : ss;
      console.log(`${ye}${mo}${da}${hh}${mm}${ss}`);
      // 通信フォーマットに合わせるため、一旦16進数テキストとして認識しint値にする
      // (通信中は16進数として扱われ、最終的に文字列に戻した後に時刻として解釈される事になる。)
      // e.g. '22'(元々の文字列) -> 34(通信用) = 0x22 (通信上) -> '22' 通信終了後書き出し時
      // これは characteristic '7c1be67c-0011-9fb8-1ae2-821d64ab0bb6' に定められた端末仕様である。
      const valArr = [
        parseInt(ye, 16),
        parseInt(mo, 16),
        parseInt(da, 16),
        parseInt(hh, 16),
        parseInt(mm, 16),
        parseInt(ss, 16),
      ];
      console.log(valArr);
      console.log('after conversion', Uint8Array.of(...valArr));
      return Uint8Array.of(...valArr);
    },
    // Connect to server(machine) via BLE
    async connectToserver(server) {
      this.overlay = true;
      this.readyForData = false;
      // syncing time
      try {
        console.log(' > getting time control service');
        // get Service for time data.
        // ここでエラー
        const timeService = await server.getPrimaryService('7c1be67c-0010-9fb8-1ae2-821d64ab0bb6');
        console.log(' > getting time characteristic');
        const timeCharacteristic = await timeService.getCharacteristic('7c1be67c-0011-9fb8-1ae2-821d64ab0bb6');
        console.log(' > characteristic obtained');
        const readyTimeArray = this.getTimeArray();
        console.log(' > time array generated, writing value...');
        await timeCharacteristic.writeValue(readyTimeArray);
        // await timeCharacteristic.writeValue(this.getTimeArray());
        console.log('> Time has been updated!');
      }
      catch (timeSyncErr) {
        console.log('!! timeSyncErr ', timeSyncErr);
      }
      // ==============
      server.getPrimaryService('7c1be67c-0000-9fb8-1ae2-821d64ab0bb6')
        .then(service => {
          this.deviceService = service;
          this.deviceDialog = false;
          this.setUpService(service);
        })
        .catch(error => {
          this.overlay = false;
          console.log('Argh! ' + error);
        });
    },
    // Invoce service via BLE
    async setUpService(service) {
      try {
        // * Bluetooth Low Energyには、キャラクタリスティックが書き換えられて，値が変更された時にその通知を受け取れる仕組みがあります。
        //   この仕組みをノーティファイ(Notify)と呼びます。ノーティファイを用いると値が自動更新されます。
        this.confirmationCharacteristic = await service.getCharacteristic('7c1be67c-000c-9fb8-1ae2-821d64ab0bb6');
        console.log('> got confirmation characteristic');
        for (let index = 1; index < 12; index += 1) {
          // convert to 16 based index.
          let ind = index.toString(16);
          const characteristic = await service.getCharacteristic(`7c1be67c-000${ind}-9fb8-1ae2-821d64ab0bb6`);
          console.log(`got characteristic ${ind}`);
          this.characteristicProgress = index;
          await characteristic.startNotifications();
          console.log(`notification started for ${characteristic.uuid}`);
          // Register event for when update characteristicValue.
          // 医療機側でデータ送信する場合に呼び出されるイベントとなる。
          characteristic.addEventListener('characteristicvaluechanged',
            this.handleDeviceNotifications);
        }
        EventBus.$emit('change-device-status', { statusCode: 5, text: this.$t('v.status_paired') });
      }
      catch (charExError) {
        console.log(charExError);
      }

      this.overlay = false;
      this.readyForData = true;
      this.characteristicProgress = 1;
      this.maxStep = 11;
    },
    // Show "歯列図"
    showToothMap() {
      this.aggregatedTreatments[this.onboarding].showMap = true;
      EventBus.$emit('show-map', this.onboarding);
    },
    // 「登録」ボタン押下で確認ポップアップを表示する
    showSubmitConfirmation() {
      this.aggregatedTreatments[this.onboarding].submitConfirmation = true;
    },
    hideSubmitConfirmation() {
      this.aggregatedTreatments[this.onboarding].submitConfirmation = false;
    },
    // Indication handler
    // 医療機側で"送信"実行した後に連続して呼ばれるイベントハンドラ。
    // ルール通りに順序立てて一通りのeventが送信され、このメソッドが何度か呼ばれる事になる。
    // 最後に飛ばされるeventの内容は明確なため、その時に送信完了としてtreatデータの表示が１つ追加される事になる。
    handleDeviceNotifications(event) {
      // Display status "on transmission".
      EventBus.$emit('change-device-status', { statusCode: 4, text: this.$t('v.status_transmission') });
      console.log('UUID', event.target.uuid);
      console.log('Raw value', event.target.value);
      // UUID sample: 7c1be67c-0009-9fb8-1ae2-821d64ab0bb6
      //              xxxxxxxx-00AA-xxxx-xxxx-xxxxxxxxxxxx
      //    -> Extract the 2 charactors of "AA". 
      const cahrUUID = event.target.uuid;
      const start = cahrUUID.indexOf('-00');
      const end = cahrUUID.indexOf('-', start + 1);
      const id = cahrUUID.substring(start + 3, end);
      const value = event.target.value;
      this.handleValue(value, id);
      // console.log('Raw value', value);
      // console.log(`> short uuid: ${id} value: ${this.handleValue(value, id)}`);
    },
    // Handle a value sent via BLE and store it to "this.deviceScanResult".
    //   val: byte array
    //   id: a part of UUID. 16bit charactor. It indicate data meaning.
    handleValue(val, id) {
      const parts = [];
      for (let i = 0; i < val.byteLength; i += 1) {
        parts.push(val.getUint8(i));
      }
      let result;
      if (id === '01') { // Date. 
        // Format as '0000-00-00' and store it.
        this.overlay = true;
        this.numberOfTransmissions += 1;
        // console.log(parts);
        const year = parts[0] < 10 ? `0${parts[0]}` : parts[0];
        const month = parts[1] < 10 ? `0${parts[1]}` : parts[1];
        const day = parts[2] < 10 ? `0${parts[2]}` : parts[2];
        this.deviceScanResult.date = `${year}-${month}-${day}`;
        console.log(`> short uuid: ${id} date: ${year}-${month}-${day}`);
        console.log('--------------');
      }
      if (id === '02') { // start time.
        // Format as '00:00' and store it.
        const hh = parts[0] < 10 ? `0${parts[0]}` : parts[0];
        const mm = parts[1] < 10 ? `0${parts[1]}` : parts[1];
        this.deviceScanResult.startTime = `${hh}:${mm}`;
        console.log(`> short uuid: ${id} start time: ${hh}:${mm}`);
        console.log('--------------');
      }
      if (id === '03') { // end time.
        // Format as '00:00' and store it.
        const hh = parts[0] < 10 ? `0${parts[0]}` : parts[0];
        const mm = parts[1] < 10 ? `0${parts[1]}` : parts[1];
        this.deviceScanResult.endTime = `${hh}:${mm}`;
        console.log(`> short uuid: ${id} end time: ${hh}:${mm}`);
        console.log('--------------');
      }
      if (id === '04') { // Total laser duration
        result = `${parts[0]}分 ${parts[1]}秒`;
        // result = this.secondsToMinutes(parts[0]);
        this.deviceScanResult.totalLaserDuration = result;
        console.log(`> short uuid: ${id} total duration: ${result}`);
        console.log(`> raw total duration: ${parts}`);
        console.log('--------------');
      }
      if (id === '05') { // Total liquid usage provided in HEX
        // arrayぶんのbyteを整数値に変換
        const liquid = parts.reduce((a, b) => (a << 8) + b);
        result = `${liquid} mL`;
        this.deviceScanResult.totalLiquid = result;
        console.log(`> short uuid: ${id} total liquid: ${result} from hex ${parseInt(result, 16)}`);
        console.log(`> raw total liquid: ${parts}`);
        console.log('--------------');
      }
      if (id === '06') { // Total number of treated teeth
        result = parseInt(parts.join(''));
        this.deviceScanResult.totalTeeth = result;
        console.log(`> raw total number of teeth: ${parts}`);
        console.log(`============== Header`);
      }
      // Per tooth detail
      if (id === '07') { // Index number
        // result = parts.join('');
        result = parseInt(parts.join(''));
        // Add new treats and then input status to this item.
        this.deviceScanResult.treats.push({
          index: null,
          number: 0,
          duration: 0,
          water: 0,
          laser: false,
        });
        // Set the result to latest/current treat.
        const ind = this.deviceScanResult.treats.length - 1;
        this.deviceScanResult.treats[ind].index = result;
        console.log(`> short uuid: ${id} index: ${result}`);
        console.log(`> raw index: ${parts}`);
        console.log('--------------');
      }
      if (id === '08') { // Tooth number
        result = parts.join('');
        // Set the result to latest/current treat.
        const ind = this.deviceScanResult.treats.length - 1;
        this.deviceScanResult.treats[ind].number = this.convertToFDINumber(result);
        // TODO: Remove later when DevKit will values have fixed
        // this.deviceScanResult.treats[ind].number = parseInt(`${this.getRandomIntInclusive(1, 4)}${this.getRandomIntInclusive(1, 8)}`);
        console.log(`> short uuid: ${id} tooth number: ${result}`);
        console.log(`> raw tooth number: ${parts}`);
        console.log('--------------');
      }
      if (id === '09') { // Laser duration
        result = `${parts[0]}分 ${parts[1]}秒`;
        // Set the result to latest/current treat.
        const ind = this.deviceScanResult.treats.length - 1;
        const duration = parts.reduce((a, b) => (a << 8) + b);
        this.deviceScanResult.treats[ind].duration = duration;
        console.log(`> short uuid: ${id} laser duration: ${result}`);
        console.log(`> raw laser duration: ${parts}`);
        console.log('--------------');
      }
      if (id === '0a') { // Liquid provided in HEX
        result = parseInt(parts.join(''));
        // Set the result to latest/current treat.
        const ind = this.deviceScanResult.treats.length - 1;
        const liquid = parts.reduce((a, b) => (a << 8) + b);
        this.deviceScanResult.treats[ind].water = liquid;
        console.log(`> short uuid: ${id} liquid: ${liquid} ml; from hex ${parseInt(result, 16)}`);
        console.log(`> raw liquid: ${parts}`);
        console.log('--------------');
      }
      if (id === '0b') { // Laser
        result = parts.join('');
        // Set the result to latest/current treat.
        const ind = this.deviceScanResult.treats.length - 1;
        this.deviceScanResult.treats[ind].laser = parseInt(result) === 1;
        console.log(`> short uuid: ${id} Laser used: ${parseInt(result) === 1}`);
        console.log(`> raw laser: ${parts}`);
        console.log('--------------');

        // At this point, all loading processes are considered to registered all data. Finish to process the treat.
        this.confirmTransmission(this.deviceScanResult.treats[ind].index);

        // const checkInd = this.deviceScanResult.treats.length - 1;
        if (this.deviceScanResult.treats.length === 1) {
          this.maxStep = this.deviceScanResult.totalTeeth - 1;
          // init the indicating progress.
          this.characteristicProgress = 1;
        }
      }
      return result;
    },
    // このファイルではもう使われてない。
    async getCharacteristicName(descriptors) { // of one characteristic !!!
      let charName = 'NA';
      for (const descriptor of descriptors) {
        switch (descriptor.uuid) {
          // eslint-disable-next-line
          case BluetoothUUID.getDescriptor('gatt.characteristic_user_description'):
            try {
              const value = await descriptor.readValue();
              let decoder = new TextDecoder('utf-8');
              // charName = decoder.decode(value);
              // console.log(decoder.decode(value));
              if (this.getOS() === 'Mac OS') {
                charName = decoder.decode(value);
              } else {
                const decodedStr = `${decoder.decode(value)}`;
                charName = decodedStr.substr(0, decodedStr.indexOf('\0'));
              }
            }
            catch (error) {
              console.log('Error in async method of descriptor.readValue(): ', error);
            }
            break;
          default: console.log('> Unknown Descriptor: ' + descriptor.uuid);
        }
      }
      return charName;
    },
    // Finish to process for the current treatment.
    async confirmTransmission(ind) {
      let confirmationBit;
      if (ind + 1 !== this.deviceScanResult.totalTeeth) {
        // 全ての歯が読み取り終えていない場合、次を待機。
        confirmationBit = Uint8Array.of(1);
      } else {
        // 全ての歯が読み取り終えたら、保存する。
        confirmationBit = Uint8Array.of(2);
        this.overlay = false;
        const treatment = {
          patient: undefined, // choose later as option
          treatmentCategory: '',
          scannedData: [],
          header: {
            date: this.deviceScanResult.date,
            startTime: this.deviceScanResult.startTime,
            endTime: this.deviceScanResult.endTime,
            totalLaserDuration: this.deviceScanResult.totalLaserDuration,
            totalLiquid: this.deviceScanResult.totalLiquid,
            totalTeeth: this.deviceScanResult.totalTeeth,
          },
          showMap: false,
          submitConfirmation: false,
          operator: this.operatorsList[0],
        };
        treatment.scannedData = this.deviceScanResult.treats;
        // 本来はここに必要では？連続で読み取ると初期化が遅れる -> this.initDeviceScanResult()
        // Finish to process the treatment and append it.
        this.aggregatedTreatments.push(treatment);
        this.toast_info("機器からデータを読み取りました")
      }
      // append progress step
      this.characteristicProgress += 1;
      // 確認メッセージを送信するらしい(医療機器で予め定められたビットを送信する)
      this.confirmationCharacteristic.writeValue(confirmationBit)
        // .then(this.sleeper(10))
        .then(() => {
          console.log(`============== confirmation has been sent for # ${ind + 1} of ${this.deviceScanResult.totalTeeth}`);
          if (confirmationBit[0] === 2) {
            this.toast_info("医療機器との接続完了を確認しました。読み取り状況を初期化します")
            // 全ての歯が読み取り終えたら、初期化する。
            this.initDeviceScanResult();
            this.readyForData = true; // 今、このフラグは使えていない、本当はこのフラグがfalseの間はBT通信をしないように画面で案内が必要なように見える。
            EventBus.$emit('change-device-status', { statusCode: 5, text: this.$t('v.status_paired') });
          }
        })
        .catch((confirmationBitError) => {
          console.log('confirmationBitError: ', confirmationBitError);
        });
    },
    ManualTermination() {
      this.confirmationCharacteristic.writeValue(Uint8Array.of(2))
        .then(() => {
          console.log('========= Manual confirmation has been sent');
          this.deviceScanResult = {
            date: null,
            startTime: null,
            endTime: null,
            totalLaserDuration: null,
            totalLiquid: null,
            totalTeeth: null,
            treats: [],
          };
        })
        .catch((confirmationBitError) => {
          console.log('Manual confirmation: ', confirmationBitError);
        });
    },
    sleeper(ms) {
      return (x) => {
        return new Promise(resolve => setTimeout(() => resolve(x), ms));
      };
    },
    // 登録直前のバリデーションチェック
    ValidateFields(target_treatment_index) {
      this.missedInput = [];
      const validate_fields_for_a_treatment = (treatment, ind) => {
        if (treatment.patient !== undefined
          && treatment.treatmentCategory !== ''
          && treatment.operator !== undefined) {
          return;
        }
        const missedItems = [];
        if (treatment.patient === undefined) {
          missedItems.push(this.$t('e.patient'));
        }
        if (treatment.treatmentCategory === '') {
          missedItems.push(this.$t('p.category'));
        }
        if (treatment.operator === undefined) {
          missedItems.push(this.$t('p.operator'));
        }
        this.missedInput.push(this.$t('message.patient_n_needs_items', { number: ind + 1, missedItems: missedItems.join(', ') }));
      }
      // this.aggregatedTreatments.forEach(validate_fields_for_a_treatment);
      // 全件チェックは廃止、１ページ分のみチェックする。
      validate_fields_for_a_treatment(this.aggregatedTreatments[target_treatment_index], target_treatment_index)
    },
    // [DEBUG] Add mock
    debugAddMock() {
      // const treatment = {
      //   patient: undefined, // choose later as option
      //   treatmentCategory: this.treatmentCategoryList[0] ?? '', // choose later
      //   scannedData: [{
      //     index: 1,
      //     number: 24,
      //     duration: 12,
      //     water: 3,
      //     laser: false,
      //   },{
      //     index: 2,
      //     number: 36,
      //     duration: 200,
      //     water: 0,
      //     laser: true,
      //   },{
      //     index: 3,
      //     number: -1,
      //     duration: 0,
      //     water: 0,
      //     laser: true,
      //   },{
      //     index: 4,
      //     number: -1,
      //     duration: 0,
      //     water: 0,
      //     laser: true,
      //   }],
      //   header: {
      //     date: '22-11-08',
      //     startTime: '12:34',
      //     endTime: '12:56',
      //     totalLaserDuration: 123,
      //     totalLiquid: 2345,
      //     totalTeeth: 5,
      //     memo: 'DEBUG用ボタンによって生成されたモックデータです。'
      //   },
      //   showMap: false,
      //   operator: this.operatorsList[0],
      // };
      if (!this.chosenDevice) { // 何も選ばれてない状態でモック入れる場合はBT端末を読み取った事にする。(テスト機のID)
        this.chosenDevice = { _id: "1662563101169x617745833797356300" };
      }
      // いったん、問題が起こったデータをモックにします。
      this.aggregatedTreatments = [
        {
          "patient": {
            "Modified Date": "2022-12-20T06:45:13.927Z",
            "Created Date": "2022-12-19T07:05:50.197Z",
            "Created By": "1671177086672x968376903612358000",
            "birthdate": "1957-05-02T15:00:00.000Z",
            "clinic_id": "1671086674797x954055935946129400",
            "id": 15,
            "registration_number": "1234",
            "phone_number": "09006554321",
            "gender": 1,
            "address": {
              "address": "日本 〒981-3222",
              "lat": 38.3304086,
              "lng": 140.7881973
            },
            "patient_registration_status_id": "1665472532950x947406232233358300",
            "name": "ID1 [1234] 佐藤花子 (女) ",
            "branch_id_on_clinic": 1,
            "_id": "1671433548956x386359992796577800"
          },
          "treatmentCategory": {
            "Modified Date": "2022-08-02T02:48:54.501Z",
            "Created Date": "2022-08-02T02:48:54.495Z",
            "Created By": "admin_user_luke-perio_test",
            "name": "歯周治療",
            "index": 1,
            "value": "PeriodonticCare",
            "_id": "1659408534495x488503776266859800"
          },
          "scannedData": [
            {
              "index": 0,
              "number": 11,
              "duration": 48,
              "water": 23,
              "laser": true
            }
          ],
          "header": {
            // "date": "23-05-09", 
            // "startTime": "14:37",
            // "endTime": "14:39",
            "date": "00-00-00", // 時刻設定ナシのテスト
            "startTime": "00:00",
            "endTime": "00:00",
            "totalLaserDuration": "0分 48秒",
            "totalLiquid": "23 mL",
            "totalTeeth": 1
          },
          "showMap": false,
          "submitConfirmation": false,
          "operator": {
            "Modified Date": 1673485179423,
            "Created Date": 1671177086672,
            "role_id": "1659407218046x151431676369143680",
            "user_name": "ササキ",
            "user_signed_up": true,
            "authentication": {
              "email": {
                "email": "ササキ@bizfreak.co.jp",
                "email_confirmed": null
              }
            },
            "user_clinic_id": "1671177085806x485956470405070850",
            "language": "ja_jp",
            "id": 28,
            "is_temp_email": true,
            "is_temp_password": false,
            "_id": "1671177086672x968376903612358000"
          }
        },
        {
          "patient": {
            "Modified Date": "2022-12-20T06:45:13.927Z",
            "Created Date": "2022-12-19T07:05:50.197Z",
            "Created By": "1671177086672x968376903612358000",
            "birthdate": "1957-05-02T15:00:00.000Z",
            "clinic_id": "1671086674797x954055935946129400",
            "id": 15,
            "registration_number": "1234",
            "phone_number": "09006554321",
            "gender": 1,
            "address": {
              "address": "日本 〒981-3222",
              "lat": 38.3304086,
              "lng": 140.7881973
            },
            "patient_registration_status_id": "1665472532950x947406232233358300",
            "name": "ID1 [1234] 佐藤花子 (女) ",
            "branch_id_on_clinic": 1,
            "_id": "1671433548956x386359992796577800"
          },
          "treatmentCategory": {
            "Modified Date": "2022-08-02T02:49:12.495Z",
            "Created Date": "2022-08-02T02:49:12.489Z",
            "Created By": "admin_user_luke-perio_test",
            "name": "メンテナンス",
            "index": 2,
            "value": "Maintenance",
            "_id": "1659408552489x682507537410356600"
          },
          "scannedData": [
            {
              "index": 0,
              "number": 11,
              "duration": 5,
              "water": 2,
              "laser": true
            },
            {
              "index": 1,
              "number": 35,
              "duration": 6,
              "water": 9,
              "laser": true
            },
            {
              "index": 2,
              "number": 32,
              "duration": 7,
              "water": 1,
              "laser": true
            },
            {
              "index": 3,
              "number": 42,
              "duration": 8,
              "water": 2,
              "laser": true
            }
          ],
          "header": {
            "date": "23-05-08",
            "startTime": "13:10",
            "endTime": "13:19",
            "totalLaserDuration": "0分 6秒",
            "totalLiquid": "9 mL",
            "totalTeeth": 1
          },
          "showMap": false,
          "submitConfirmation": false,
          "operator": {
            "Modified Date": 1673485179423,
            "Created Date": 1671177086672,
            "role_id": "1659407218046x151431676369143680",
            "user_name": "ササキ",
            "user_signed_up": true,
            "authentication": {
              "email": {
                "email": "ササキ@bizfreak.co.jp",
                "email_confirmed": null
              }
            },
            "user_clinic_id": "1671177085806x485956470405070850",
            "language": "ja_jp",
            "id": 28,
            "is_temp_email": true,
            "is_temp_password": false,
            "_id": "1671177086672x968376903612358000"
          }
        }
      ]
      this.toast_info("テストデータを追加しました。")
    },
    // 受信し画面に表示されている全データをDBに登録して取得済みデータ(this.aggregatedTreatments)をクリアする。
    submitData(target_treatment_index) {
      // Method flow: Submit a treatment_session and then submit treatments in "submitTreatment" method.
      this.completeUpload = false;

      // Vallidation error check
      this.ValidateFields(target_treatment_index);
      if (this.missedInput.length > 0) {
        // Occur validation error! 
        this.showValidationAlert = true;
        this.$nextTick(() => {
          if (document.querySelector('#validation_alert') !== null) {
            this.computedAlertHeight2 = document.querySelector('#validation_alert').offsetHeight + 16;
            this.tableReRenderCounter += 1;
          }
        })
        this.toast_error("登録に必要な内容が残っています。入力内容を確認してください。")
      } else {
        // Validation OK!
        // アップしようとしているデータをまるごとログに残す。
        this.storeServerLog(JSON.stringify(this.aggregatedTreatments), "TryingToUpload");
        // If no error, proceed to store the data.
        this.showValidationAlert = false;
        // this.confirmSubmissionDialog = true; TODO: decide if confirmation dialog necessary
        this.overlay = true;
        // IDを決めるため、現時点で最新のIDを取得する。
        this.toast_info("記録ID付番を開始します")
        this.getLatestTreatmentSessionID()
          .then((latest_treatment_session_id) => {
            this.toast_info("記録ID付番完了")
            // Generate bunch of treatment sessions
            const treatment_Session_requests = [];
            const process_a_treatment = (treatment_session) => {
              // 時刻が渡されなかったら、2000/01/01 00:00で登録するように
              const recorderAt =
                treatment_session.header.date == '00-00-00' ?
                  '2000/01/01 00:00+09:00'
                  : `20${treatment_session.header.date}T${treatment_session.header.endTime}:00+09:00`;
              console.log('recorderAt: ', recorderAt);
              console.log('clinic_id: ', this.$ls.get('currentClinic')._id);
              console.log('user_operator_id: ', treatment_session.operator.user_clinic_id);
              console.log('device_id: ', this.chosenDevice._id);
              console.log('user_patient_id: ', treatment_session.patient._id);
              console.log('treatment_category_id: ', treatment_session.treatmentCategory._id);
              treatment_Session_requests.push(
                axios({
                  method: 'post',
                  url: `${process.env.VUE_APP_API_BASEURL}/obj/treatment_sessions`,
                  headers: {
                    Accept: 'application/json',
                  },
                  params: {
                    'api_token': this.$ls.get('api_token'),
                  },
                  data: {
                    id: ++latest_treatment_session_id,
                    recorded_at: recorderAt,
                    clinic_id: this.$ls.get('currentClinic')._id, // bubble側で自動付番されるid
                    user_operator_id: treatment_session.operator.user_clinic_id,
                    device_id: this.chosenDevice._id,
                    user_patient_id: treatment_session.patient._id, // this.chosenPatient.id,
                    treatment_category_id: treatment_session.treatmentCategory._id,
                    memo: treatment_session.memo,
                    periorank: 0, // TODO: Remove later this field will be set up at other place
                  },
                })
              );// finish treatment_Session_requests.push()
            }
            // this.aggregatedTreatments.forEach(process_a_treatment);
            // 全件処理は廃止。1ページ分のみ処理する。
            process_a_treatment(this.aggregatedTreatments[target_treatment_index])

            this.toast_info("診療記録を送信します")
            // Exec all requests to store treatment_sessions "at once".
            Promise.all(treatment_Session_requests)
              .then((treatment_sessions) => {
                this.overlay = false;
                this.$notify({
                  group: 'notification',
                  title: `Treatments sessions uploaded!`,
                  type: 'success',
                  position: 'top center',
                  text: 'All treatments sessions has been successfully uploaded',
                });
                // Convert treat_Sessions -> id array of treatment sessions.
                // And call another method and store all treatments.
                // bubbleからのレスポンスでは [].data.id <- ここに treatment_sessions._idが入っている。
                this.toast_info("診療記録の送信に成功")
                this.submitTreatment(target_treatment_index, treatment_sessions.map((treat_session) => treat_session.data.id));
              })
              .catch((treatment_session_requests_error) => {
                this.overlay = false;
                console.log('treatment_Session_requests Error: ', treatment_session_requests_error);
                this.toast_error("データ登録に失敗しました。 on aggregatedTreatments登録時")
                let error_msg = treatment_session_requests_error.message + " " + ( treatment_session_requests_error.response && treatment_session_requests_error.response.data && treatment_session_requests_error.response.data.body && treatment_session_requests_error.response.data.body.message ? treatment_session_requests_error.response.data.body.message : "");
                this.$notify({
                  group: 'notification',
                  title: `エラー ${treatment_session_requests_error.response.status}`,
                  type: 'error',
                  position: 'top center',
                  text: error_msg,
                });
                console.log(error_msg);
                this.storeServerLog("treatment_Session_requestsの処理でエラー", "DetectError");
              });
          }); // end: .then((latestTreatmentSessionId)=>{
      } // end: else
    },
    // submitData()によって呼ばれる。"treatments"テーブルへの書き込み。
    submitTreatment(target_treatment_index, treatment_session_ids) {
      const treatments_requests = [];

      // creating array of promises (requestes)
      treatment_session_ids.forEach((treatment_session_id, index) => {
        // For all treatments(scannedData)
        // 4*8通りの全レコードを常に生成する。機器から渡されなかったものは空欄。
        let treatment_data_list = []
        for (let i = 1; i <= 4; i++) {
          for (let j = 1; j <= 8; j++) {
            let tooth_number = `${i}${j}`
            let target_scanned_data = this.aggregatedTreatments[index].scannedData.find(
              (d) => { return '' + d.number == tooth_number }
            )
            let send_data = null;
            if (target_scanned_data) {
              // データがあった場合
              send_data = {
                m_tooth_id: tooth_number,
                treatment_session_id: treatment_session_id,
                average_of: target_scanned_data.average,
                duration: target_scanned_data.duration,
                laser: target_scanned_data.laser,
                water: target_scanned_data.water,
                is_saving: false
              }
            } else {
              // データがなかった場合, 空データを生成
              send_data = {
                m_tooth_id: tooth_number,
                treatment_session_id: treatment_session_id,
                is_saving: false
              }
            }
            treatment_data_list.push(send_data)

            // リクエストデータ追加！
          }// end: for
        }// end: for
        // WFを使った登録方法。今後はこのやり方で。
        const treatment_data_string_list = treatment_data_list.map(data => JSON.stringify(data));
        console.debug("debug treatment_data_string > ", treatment_data_string_list);
        let axios_promise = axios({
          method: 'post',
          url: `${process.env.VUE_APP_API_BASEURL}/wf/save-treatment`,
          headers: { Accept: 'application/json', },
          params: { 'api_token': this.$ls.get('api_token'), },
          data: {
            "data": treatment_data_string_list,
            "treatment_session_id": treatment_session_id,
            "iteration": 1,
            "mode": "create"
          }
        })
        treatments_requests.push(axios_promise)
      }); // finish Treatment_session_ids.forEach()
      this.toast_info("診療明細を送信します")
      // performing all promises "at once".
      Promise.all(treatments_requests)
        .then((/* responses */) => {
          this.toast_info("診療明細の送信に成功。データの登録が正常に完了しました。")
          this.completeUpload = true;
          this.deviceDialog = false;
          // this.chosenDevice = undefined;

          // target_treatment_indexの位置にあるデータのみを削除する。
          this.aggregatedTreatments.splice(target_treatment_index, 1);
          // 全件処理されていた場合
          if (this.aggregatedTreatments.length == 0) {
            // 今の所、何もすることはない。
          }

          // this.storeServerLog("treatments_requestsの処理でまで全て完了", "SuccessUploading");
        })
        .catch((err) => {
          this.completeUpload = true;
          this.toast_error("データ登録に失敗しました。 on treatments登録時")
          console.log(err);
          this.$notify({
            group: 'notification',
            title: `Treatments uploaded!`,
            type: 'success',
            position: 'top center',
            text: 'All treatments has been successfully uploaded',
          });
          this.storeServerLog("treatments_requestsの処理でエラー", "DetectError");
        })
        .catch((treatments_requests_error) => {
          this.overlay = false;
          let error_msg = treatments_requests_error.message + " " + (treatments_requests_error.response.data && treatments_requests_error.response.data.body && treatments_requests_error.response.data.body.message ? treatments_requests_error.response.data.body.message : "");
          this.$notify({
            group: 'notification',
            title: `エラー ${treatments_requests_error.response.status}`,
            type: 'error',
            position: 'top center',
            text: error_msg,
          });
          console.log(treatments_requests_error);
          console.log(error_msg);
          this.storeServerLog("treatments_requestsの処理でエラー", "DetectError");
        }); // end: .catch(
    },
    // BubbleDBにログを保存する
    storeServerLog(description, status) {
      axios({
        method: 'post',
        url: `${process.env.VUE_APP_API_BASEURL}/obj/data_transmission_logs`,
        headers: { Accept: 'application/json', },
        params: { 'api_token': this.$ls.get('api_token'), },
        data: {
          description: description,
          status: status,
        },
      })
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error.message);
          console.log(error.data);
          console.log(error.response);
          this.toast_error("ログ保存に失敗")
        }); // end of axios
    },
    getOS() {
      let userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;

      if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS';
      } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS';
      } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows';
      } else if (/Android/.test(userAgent)) {
        os = 'Android';
      } else if (!os && /Linux/.test(platform)) {
        os = 'Linux';
      }
      return os;
    },
    calculatedCharacteristicProgress() {
      if (this.characteristicProgress !== this.maxStep) {
        return Math.round(this.characteristicProgress * 100 / this.maxStep);
      }
      return 100;
    },
    // convert value from Yoshidas numbering system to FDI ISO standard
    convertToFDINumber(deviceToothNubmer) {
      // formulas derived heuristically
      let conversionResult = 0;
      let preProcessedValue = parseInt(deviceToothNubmer, 10);
      switch (true) {
        case (preProcessedValue >= 1 && preProcessedValue <= 8):
          conversionResult = 19 - preProcessedValue;
          break;
        case (preProcessedValue >= 9 && preProcessedValue <= 16):
          conversionResult = 12 + preProcessedValue;
          break;
        case (preProcessedValue >= 17 && preProcessedValue <= 24):
          conversionResult = 55 - preProcessedValue;
          break;
        case (preProcessedValue >= 25 && preProcessedValue <= 32):
          conversionResult = 16 + preProcessedValue;
          break;
        // Milk teeth
        case (preProcessedValue >= 33 && preProcessedValue <= 37):
          conversionResult = 88 - preProcessedValue;
          break;
        case (preProcessedValue >= 38 && preProcessedValue <= 42):
          conversionResult = 23 + preProcessedValue;
          break;
        case (preProcessedValue >= 48 && preProcessedValue <= 52):
          conversionResult = 33 + preProcessedValue;
          break;
        case (preProcessedValue >= 43 && preProcessedValue <= 47):
          conversionResult = 118 - preProcessedValue;
          break;
        default:
          conversionResult = -1;
          break;
      }
      console.log(`origin ${deviceToothNubmer} - result ${conversionResult}`);
      return conversionResult;
    },
    secondsToMinutes(time) {
      // from https://code.labstack.com/HVdZZYqH
      // Hours, minutes and seconds
      var hrs = ~~(time / 3600);
      var mins = ~~((time % 3600) / 60);
      var secs = ~~time % 60;
      // Output like "1:01" or "4:03:59" or "123:03:59"
      var ret = '';
      if (hrs > 0) {
        ret += '' + hrs + ':' + (mins < 10 ? '0' : '');
      }
      // ret += "" + mins + ":" + (secs < 10 ? "0" : "");
      ret += '' + mins + '分' + (secs < 10 ? '0' : '');

      ret += '' + secs + '秒';
      return ret;
    },
    getOperatorsList() {
      axios({
        method: 'get',
        url: `${process.env.VUE_APP_API_BASEURL}/wf/get_available_operators_for_data_transmission`,
        headers: {
          Accept: 'application/json',
        },
        params: {
          'api_token': this.$ls.get('api_token'),
          "clinic_id": this.$ls.get('currentClinic')._id
        },
      })
        .then((response) => {
          console.log('get operators');
          console.log(response.data.response.operators);
          this.operatorsList = response.data.response.operators;
        })
        .catch((error) => {
          // TODO: Notification should be there
          console.log(error.message);
          console.log(error.data);
          console.log(error.response);
        }); // end of axios
    },
    // temporary method for debuging
    generateMockData() {
      const size = this.getRandomIntInclusive(2, 6);
      for (let i = 0; i < size; i += 1) {
        const treatment = {
          patient: undefined,
          operator: this.$ls.get('user_role') === 'SharedAccount' ? undefined : this.$ls.get('user'),
          treatmentCategory: '',
          scannedData: [],
          showMap: false,
          submitConfirmation: false
        };
        const randomIndexes = [];
        for (let j = 0; j < this.getRandomIntInclusive(1, 32); j++) {
          const index = this.getRandomIntInclusive(0, 31);
          if (randomIndexes.findIndex((ind) => ind === index) !== -1) {
            j -= 1;
            continue;
          }
          randomIndexes.push(index);
        }
        for (let j = 0; j < randomIndexes.length; j++) {
          treatment.scannedData.push(this.mockScannedData[randomIndexes[j]]);
        }
        this.aggregatedTreatments.push(treatment);
      }
    },
    getRandomIntInclusive(min, max) {
      const Min = Math.ceil(min);
      const Max = Math.floor(max);
      return Math.floor(Math.random() * (Max - Min + 1) + Min);
    }
  },
}
</script>

<style lang="scss" scoped></style>
